import React, { Component } from "react"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import SEO from "../components/base/seo"
import { Language } from "../store/reducers/utilsReducer"
import { localeContent } from "../utils/locale"

const language = Language.EN

export default class ReferralFaqs extends Component {
  renderFAQs = () => {
    const faqView: any = []
    const faqsList = localeContent(language).referralFaqs.faqs.faqlist

    faqsList.map((eachFAQ, index) => {
      let containerClassName = "w-full "
      if (index < faqsList.length - 1) {
        containerClassName += "border-gray-200 border-b-2"
      }

      faqView.push(
        <dl className={containerClassName} key={index}>
          <div className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
            <dt className="text-base font-medium text-gray-900 md:col-span-5">
              {eachFAQ.question}
            </dt>
            <dd className="mt-2 md:mt-0 md:col-span-7">
              <p className="text-base text-gray-500 whitespace-pre-line">
                {eachFAQ.answer}
              </p>
            </dd>
          </div>
        </dl>
      )
      return null
    })

    return faqView
  }

  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <SEO
          title="Arus Oil - Referral Programme Frequently Asked Questions"
          description="Having questions for referring your friends in recycling your used cooking oil ? View our frequently asked questions in Arus Oil to answer your doubts"
        />
        <Header page="Referral Faqs" />
        <div className="py-10">
          <header>
            <div className="pt-4 max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900">
                {localeContent(language).referralFaqs.title}
              </h1>
            </div>
          </header>

          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="block px-4 py-8 sm:px-0">
                <div className="flex flex-col mt-2">
                  <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                    <div className="bg-white px-4 py-3 flex flex-col items-center justify-between border-t border-gray-200 sm:px-6">
                      {this.renderFAQs()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    )
  }
}
